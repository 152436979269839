export const routes = [
  {
    name: "notfound",
    path: "/notfound",
    meta: { title: "404 Page not found" },
    component: () => import("@/components/pages/404"),
  },
  {
    name: "home",
    path: "/",
    component: () => import("@/components/ExampleComponent.vue"),
  },
  // {
  //   name: "demo",
  //   path: "/demo",
  //   component: () => import("@/components/DemoComponent"),
  // },
  {
    name: "assignment",
    path: "/assignment",
    meta: { title: "Assignment" },
    component: () => import("@/components/assignment/Main"),
  },
  {
    name: "practice",
    path: "/practice",
    meta: { title: "Practice" },
    component: () => import("@/components/practice/Main"),
  },
  {
    name: "text-image-video",
    path: "/text-image-video",
    meta: { title: "Text, Image, Video" },
    component: () => import("@/components/textImageVideo/TextImageVideo.vue"),
  },
  {
    name: "reading-text-hybrid",
    path: "/reading-text-hybrid",
    meta: { title: "Reading text hybrid" },
    component: () => import("@/components/readTextHybrid/Main"),
  },
  {
    name: "reading-text-tooltip",
    path: "/reading-text-tooltip",
    meta: { title: "Reading text tooltip" },
    component: () => import("@/components/readTextTooltip/Main"),
  },
  {
    name: "carousel",
    path: "/carousel",
    meta: { title: "Carousel" },
    component: () => import("@/components/carousel/Carousel"),
  },
  {
    name: "gap2",
    path: "/gap2",
    meta: { title: "Gap filling 2" },
    component: () => import("@/components/gapFilling2/Main"),
  },
  {
    name: "open-ended",
    path: "/open-ended",
    meta: { title: "Open-ended" },
    component: () => import("@/components/openEnded/OpenEnded"),
  },
  {
    name: "matching1",
    path: "/matching1",
    meta: { title: "Matching 1" },
    component: () => import("@/components/matching1/Matching1"),
  },
  {
    name: "matching2",
    path: "/matching2",
    meta: { title: "Matching 2" },
    component: () => import("@/components/matching2/Main"),
  },
  {
    name: "matching3",
    path: "/matching3",
    meta: { title: "Matching 3" },
    component: () => import("@/components/matching3/Matching3"),
  },
  {
    name: "pronunciation-record",
    path: "/pronunciation-record",
    meta: { title: "Pronunciation record" },
    component: () => import("@/components/pronunciationRecord/Main"),
  },
  {
    name: "test",
    path: "/test",
    meta: { title: "Test" },
    component: () => import("@/components/test/Main"),
  },
  // Preview
  {
    name: "Text Image Video Preview",
    path: "/text-image-video-preview",
    meta: { title: "Text Image Video Preview" },
    component: () =>
      import("@/components/preview/textImageVideo/TextImageVideo.vue"),
  },
  {
    name: "Carousel Preview",
    path: "/carousel-preview",
    meta: { title: "Carousel Preview" },
    component: () => import("@/components/preview/carousel/Carousel.vue"),
  },
  {
    name: "Reading Text Tooltip Preview",
    path: "/reading-text-tooltip-preview",
    meta: { title: "Reading Text Tooltip Preview" },
    component: () =>
      import("@/components/preview/readingTextTooltip/ReadingTextTooltip.vue"),
  },
  {
    name: "Reading Text Hybrid Preview",
    path: "/reading-text-hybrid-preview",
    meta: { title: "Reading Text Hybrid Preview" },
    component: () =>
      import("@/components/preview/readingTextHybrid/ReadingTextHybrid.vue"),
  },
  {
    name: "Gap Filling 2 Preview",
    path: "/gap2-preview",
    meta: { title: "Gap filling 2 Preview" },
    component: () => import("@/components/preview/gap2/Gap2.vue"),
  },
  {
    name: "Open Ended Preview",
    path: "/open-ended-preview",
    meta: { title: "Open Ended Preview" },
    component: () => import("@/components/preview/openEnded/OpenEnded.vue"),
  },
  {
    name: "Matching 1 Preview",
    path: "/matching1-preview",
    meta: { title: "Matching 1 Preview" },
    component: () => import("@/components/preview/matching1/Matching1"),
  },
  {
    name: "Matching 2 Preview",
    path: "/matching2-preview",
    meta: { title: "Matching 2 Preview" },
    component: () => import("@/components/preview/matching2/Main"),
  },
  {
    name: "Matching 3 Preview",
    path: "/matching3-preview",
    meta: { title: "Matching 3 Preview" },
    component: () => import("@/components/preview/matching3/Matching3"),
  },
  {
    name: "MCQ & Gap 1 Preview",
    path: "/mcq-gap1-preview",
    meta: { title: "MCQ & Gap 1 Preview" },
    component: () => import("@/components/preview/mcq-gap1/MCQGap1"),
  },
  {
    name: "Pronunciation Record Preview",
    path: "/pronunciation-record-preview",
    meta: { title: "Pronunciation Record Preview" },
    component: () =>
      import(
        "@/components/preview/pronunciationRecord/PronunciationRecord.vue"
      ),
  },
  {
    name: "Bài tập tự luận Preview",
    path: "/essay-preview",
    meta: { title: "Bài tập tự luận Preview" },
    component: () => import("@/components/preview/essay/Essay"),
  },
  {
    name: "MCQ Preview",
    path: "/mcq-preview",
    meta: { title: "MCQ Preview" },
    component: () => import("@/components/preview/mcq/MCQ"),
  },
  {
    name: "Gap Filling 1 Preview",
    path: "/gap1-preview",
    meta: { title: "Gap filling 1 Preview" },
    component: () => import("@/components/preview/gap1/Gap1"),
  },
  {
    name: "Assignment Preview",
    path: "/assignment-preview",
    meta: { title: "Assignment Preview" },
    component: () => import("@/components/preview/test-assignment/Main"),
  },
  {
    name: "Test Preview",
    path: "/test-preview",
    meta: { title: "Test Preview" },
    component: () => import("@/components/preview/test-assignment/Main"),
  },
  {
    name: "Assignment-TP",
    path: "/tp/assignment",
    meta: { title: "Assignment" },
    component: () => import("@/components/tp/assignment/Main"),
  },
  {
    name: "Test-TP",
    path: "/tp/test",
    meta: { title: "Test" },
    component: () => import("@/components/tp/test/Main"),
  }
];
